@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap");

html,
body,
#root,
.app {
    height: 100%;
    width: 100%;
    font-family: "Rubik", sans-serif;
}
