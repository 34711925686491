.button-36 {
    background-image: linear-gradient(
        92.88deg,
        #455eb5 9.16%,
        #5643cc 43.89%,
        #673fd7 64.72%
    );
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    flex-shrink: 0;
    font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont,
        "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
    font-size: 2rem; /* Increased font size */
    font-weight: 500;
    height: 4rem;
    padding: 0 1.6rem;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
    transition: all 0.5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 33%;

    /* Define shake animation */
    animation: shake 0.5s ease-in-out infinite;

    /* Apply lift on hover */
    transform: translateY(0);
}


.overflow-hidden {
    overflow: hidden;
}

.helper-accordion {
    margin-bottom: 1rem;
}

.helper-accordion.Mui-expanded {
    border: 1px solid #000;
}
